import React, { useState, Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import HeroSection from "../components/common/hero-section"
import Seo from "../components/seo"
import { RichText } from "prismic-reactjs"
import { MdCalendarToday } from "react-icons/md"
import { Dialog, Transition } from "@headlessui/react"
import htmlSerializer from "../utils/htmlSerializer"

import LayoutNew from "../components/layout-new"
import { VimeoPlayer } from 'reactjs-vimeo-player'
import "../styles/virtualsymposium.css"

const pStyle = "text-base mb-6"

const Card = ({
  title,
  presentor,
  children,
  month,
  day,
  time,
  eventId,
  videoId,
  isOdd,
}) => {
  let [isOpen, setIsOpen] = useState(false)

  const eventDate = Date.parse(
    `${day} ${month} ${new Date().getFullYear().toString()}`
  )
  const today = new Date().setHours(0, 0, 0, 0)

  return (
    <div
      className={`w-full flex flex-col py-10 md:py-14 ${
        isOdd ? "bg-gray-200" : "bg-white"
      }`}
    >
      <div className="max-w-screen-lg w-full mx-auto flex flex-col md:flex-row items-center justify-center">
        {videoId !== null ? (
          <div className="w-full px-2 md:px-0 md:w-6/12 h-full">
            <VimeoPlayer
              id={videoId}
              width={640}
              height={480}
              responsive
              className="shadow-lg"
            />
          </div>
        ) : (
          <div
            className={`flex flex-col w-1/2 md:w-6/12 shadow-lg items-center justify-center py-6 ${
              isOdd ? "bg-white" : "bg-gray-200"
            }`}
          >
            <div
              className={`text-gray-800 text-base w-24 sm:w-full h-24 sm:h-48`}
            >
              <MdCalendarToday className="w-full h-full" />
              <div className="relative -top-50% text-center -mt-4 text-base sm:text-3xl">
                <p className="uppercase">{month}</p>
                <p>{day}</p>
              </div>
            </div>
            <div className="text-center text-xl sm:text-3xl font-bold italic">
              {time}
            </div>
          </div>
        )}
        <div className="flex flex-col px-4 sm:pl-10 mt-4 w-full pt-4 sm:pt-0 items-center justify-center">
          <h1 className="text-xl md:text-3xl uppercase font-extrabold text-center tracking-wider">
            {title}
          </h1>
          <hr className="border-b border-green-900 mt-3 w-full max-w-md" />
          <p className="mb-8 text-center">{presentor}</p>
          <div className="w-full mt-5">{children}</div>
          {eventDate >= today ? (
            <div className="mt-5 w-full md:pl-5">
              <button
                onClick={e => {
                  setIsOpen(true)
                  e.preventDefault()
                }}
                className="w-full md:max-w-max uppercase bg-site-red text-white text-center px-4 p-2"
              >
                Register Now
              </button>
              {/* <a
              href={`https://my.demio.com/ref/${eventId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full md:max-w-max uppercase bg-site-red text-white text-center px-4 p-2"
            >
              Register Now
            </a> */}
              <Transition
                appear
                show={isOpen}
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog
                  as="div"
                  className="fixed inset-0 z-10 overflow-y-auto flex justify-center items-center"
                  onClose={() => {
                    setIsOpen(false)
                  }}
                >
                  <div className="min-h-screen px-4 text-center">
                    <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70" />

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                      className="inline-block h-screen align-middle"
                      aria-hidden="true"
                    >
                      &#8203;
                    </span>
                    <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Event: {title}
                      </Dialog.Title>
                      <Dialog.Description as="h4" className="text-base">
                        {`${month} ${day}  (${time})`}
                      </Dialog.Description>
                      <div>
                        <form
                          target="_top"
                          method="post"
                          action={`https://my.demio.com/reg/${eventId}`}
                          encType="application/x-www-form-urlencoded"
                          className="flex flex-col gap-y-4 sm:gap-y-2 mt-10 text-sm sm:text-base"
                        >
                          <div>
                            <label className="flex flex-col sm:flex-row items-center gap-x-10">
                              <span className="w-full sm:w-3/12 font-semibold">
                                First Name *
                              </span>
                              <input
                                type="text"
                                name="name"
                                placeholder="First Name"
                                required=""
                                maxLength="120"
                                className="px-2 py-1 w-full sm:w-9/12 border border-gray-500"
                              />
                            </label>
                          </div>
                          <div>
                            <label className="flex flex-col sm:flex-row items-center gap-x-10">
                              <span className="w-full sm:w-3/12 font-semibold">
                                Last Name *
                              </span>
                              <input
                                type="text"
                                name="last_name"
                                placeholder="Last Name"
                                required=""
                                maxLength="120"
                                className="px-2 py-1 w-full sm:w-9/12 border border-gray-500"
                              />
                            </label>
                          </div>
                          <div>
                            <label className="flex flex-col sm:flex-row items-center gap-x-10">
                              <span className="w-full sm:w-3/12 font-semibold">
                                Company
                              </span>
                              <input
                                type="text"
                                name="company"
                                placeholder="Company"
                                maxLength="120"
                                className="px-2 py-1 w-full sm:w-9/12 border border-gray-500"
                              />
                            </label>
                          </div>
                          <div>
                            <label className="flex flex-col sm:flex-row items-center gap-x-10">
                              <span className="w-full sm:w-3/12 font-semibold">
                                Email *
                              </span>
                              <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                required=""
                                maxLength="254"
                                pattern="^\S+@\S+\.\S+$"
                                className="px-2 py-1 w-full sm:w-9/12 border border-gray-500"
                              />
                            </label>
                          </div>
                          <div>
                            <label>
                              <div className="flex flex-row pt-4">
                                <input
                                  type="hidden"
                                  name="gdpr"
                                  value="False"
                                />
                                <input
                                  type="checkbox"
                                  name="gdpr"
                                  className="mt-1"
                                />
                                <span className="pl-2 text-xs sm:text-sm">
                                  Yes, please allow SRA 831(b) Admin to keep in
                                  touch with additional marketing communication.
                                </span>
                              </div>
                            </label>
                          </div>
                          <button
                            type="submit"
                            className="w-full text-white bg-site-red py-2 my-4"
                          >
                            REGISTER
                          </button>
                          <div className="text-xs sm:text-sm">
                            {`By registering, you agree to the `}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://demio.com/registration-terms-and-conditions"
                              className="underline text-site-red"
                            >
                              terms
                            </a>
                            {` and `}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.banzai.io/legal/privacy-policy"
                              className="underline text-site-red"
                            >
                              privacy policy
                            </a>
                            .
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </div>
          ) : (
            <div className="mb-2">&nbsp;</div>
          )}
        </div>
      </div>
    </div>
  )
}

const PageVirtualSymposium = () => {
  const data = useStaticQuery(graphql`
    query PageVirtualSymposiumQuery {
      prismicVirtualSymposium {
        _previewable
        data {
          page_title
          page_short_description
          page_hero_background {
            gatsbyImageData(width: 1920)
          }
          red_bubble_text
          bio_section {
            name
            position
            short_bio
            section_background
            photo {
              alt
              gatsbyImageData(width: 367)
            }
          }
          events {
            event_title
            sub_title
            event_description {
              richText
            }
            month
            day
            time
            event_id
            video_id
          }
        }
      }
      photoPlaceholder: file(
        relativePath: { eq: "team/team-photo-placeholder.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 367)
        }
      }
      imgHero: file(
        relativePath: { eq: "backgrounds/hero-team-bg-cropped.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 1920)
        }
      }
    }
  `)

  const doc = data.prismicVirtualSymposium
  const imgHero = getImage(doc.data.page_hero_background)
  const imgSrc = getSrc(doc.data.page_hero_background)
  const photoPlaceholder = getImage(data.photoPlaceholder)

  return (
    <LayoutNew>
      <Seo
        title={doc.data.page_title}
        description={doc.page_short_description}
        image={imgSrc}
      />

      <HeroSection
        imgSrc={imgHero ? imgHero : null}
        title={doc.data.page_title}
      />
      {doc.data.red_bubble_text ? (
        <div>
          <div className="w-11/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-red-800 mx-auto py-12 px-6 md:px-16 -mt-8 md:-mt-16 z-20 rounded-3xl shadow-lg">
            <h2 className="text-white font-medium text-center text-lg md:text-xl">
              {doc.data.red_bubble_text}
            </h2>
          </div>
        </div>
      ) : null}

      <div className="w-full">
        {doc.data.events?.map((event, index) => {
          return (
            <Card
              key={`card-${index}`}
              month={event.month}
              day={event.day}
              time={event.time}
              title={event.event_title}
              presentor={event.sub_title}
              eventId={event.event_id}
              isOdd={index % 2 > 0 ? true : false}
              videoId={event.video_id}
            >
              <div className="text-sm">
                <RichText
                  render={event.event_description.richText}
                  htmlSerializer={htmlSerializer}
                />
              </div>
            </Card>
          )
        })}
      </div>
      <div className="w-full flex flex-col">
        <div className="pt-8 sm:pt-24">
          <h2 className="text-site-red text-4xl md:text-6xl font-extrabold text-center mt-4 uppercase">
            About our Presenters
          </h2>
        </div>
        {doc.data.bio_section.map((presenter, index) => {
          const teamPhoto = getImage(presenter.photo)
          return (
            <div
              key={`presenter-${index}`}
              className={`${
                presenter.section_background ? "bg-gray-100" : "bg-white"
              } pt-16 md:pt-24 pb-12 md:pb-24`}
            >
              <div className="relative w-11/12 md:w-8/12 xl:w-10/12 max-w-screen-lg flex flex-col xl:flex-row items-center mx-auto xl:space-x-6">
                <div className="w-full xl:w-4/12 flex justify-center p-6">
                  <GatsbyImage
                    image={teamPhoto ? teamPhoto : photoPlaceholder}
                    alt={
                      presenter.photo.alt
                        ? presenter.photo.alt
                        : `presenter${index}`
                    }
                    className="rounded-lg shadow-lg"
                  />
                </div>
                <div className="w-full xl:w-8/12">
                  <div className="mb-2">
                    <h2 className="text-site-red text-xl md:text-2xl uppercase font-extrabold text-center tracking-wider">
                      {presenter.name ? presenter.name : "Team Name"}
                    </h2>
                  </div>
                  <div className="lg:w-8/12 mx-auto border-t-2 border-site-gray-dark pt-2 mb-8">
                    <h3 className="text-site-gray-dark text-sm md:text-base uppercase font-bold text-center tracking-wider">
                      {presenter.position ? presenter.position : "Position"}
                    </h3>
                  </div>

                  <p className={pStyle}>
                    {presenter.short_bio
                      ? presenter.short_bio
                      : "Short bio should go here."}
                  </p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </LayoutNew>
  )
}

export default withPrismicPreview(PageVirtualSymposium)
